import React, { useRef, useEffect, useState } from "react";
import * as HolisticPose from "@mediapipe/holistic";
import { Holistic } from "@mediapipe/holistic";
import * as cam from "@mediapipe/camera_utils";
import Webcam from "react-webcam";
import * as tf from "@tensorflow/tfjs";
import PropTypes from "prop-types";

import {
  drawConnectors,
  drawLandmarks,
} from "@mediapipe/drawing_utils/drawing_utils";

import extract_keypoints from "./extractkeypoints";

const ACTIONS = ["no", "yes", "sick", "help", "play", "stop"];

HolisticSign.propTypes = {
  model: PropTypes.object.isRequired,
};

export default function HolisticSign({ model }) {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [sequence, setSequence] = useState([]);
  const [predictedWord, setPredictedWord] = useState([]);

  var camera = null;

  function onResults(results) {
    // console.log('result:',results);
    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;

    // set canvas width
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(
      results.segmentationMask,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );

    // Only overwrite existing pixels.
    canvasCtx.globalCompositeOperation = "source-in";
    canvasCtx.fillStyle = "#00FF00";
    canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);

    // Only overwrite missing pixels.
    canvasCtx.globalCompositeOperation = "destination-atop";
    canvasCtx.drawImage(
      results.image,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );

    canvasCtx.globalCompositeOperation = "source-over";

    drawConnectors(
      canvasCtx,
      results.poseLandmarks,
      HolisticPose.POSE_CONNECTIONS,
      { color: "#ff0400", lineWidth: 4 }
    );
    drawLandmarks(canvasCtx, results.poseLandmarks, {
      color: "#ff0400",
      lineWidth: 2,
    });
    drawConnectors(
      canvasCtx,
      results.faceLandmarks,
      HolisticPose.FACEMESH_TESSELATION,
      { color: "#C0C0C070", lineWidth: 1 }
    );
    drawConnectors(
      canvasCtx,
      results.leftHandLandmarks,
      HolisticPose.HAND_CONNECTIONS,
      { color: "#CC0000", lineWidth: 5 }
    );
    drawLandmarks(canvasCtx, results.leftHandLandmarks, {
      color: "#00FF00",
      lineWidth: 2,
    });
    drawConnectors(
      canvasCtx,
      results.rightHandLandmarks,
      HolisticPose.HAND_CONNECTIONS,
      { color: "#00CC00", lineWidth: 5 }
    );
    drawLandmarks(canvasCtx, results.rightHandLandmarks, {
      color: "#FF0000",
      lineWidth: 2,
    });
    canvasCtx.restore();

    //prediction
    const keypoints = extract_keypoints(results).arraySync();
    setSequence((oldArray) => [...oldArray, keypoints]);
  }

  useEffect(() => {
    const temp = sequence.slice(-20); // get last 30 sequences

    if (temp.length == 20) {
      const temp2 = tf.expandDims(temp, 0);
      let res = model.predict(temp2);

      const tensorData = res.dataSync();
      const tensorArray = [];
      console.log("length of tensorData:", tensorData);
      for (var i = 0; i < tensorData.length; i++) {
        tensorArray.push(tensorData[i]);
      }
      // console.log("probability:", tensorArray);
      var indexOfMaxValue = tensorArray.reduce(
        (iMax, x, i, arr) => (x > arr[iMax] ? i : iMax),
        0
      );
      console.log("index of max value:", indexOfMaxValue);
      console.log("Predicting Action:", ACTIONS[indexOfMaxValue]);

      setPredictedWord(ACTIONS[indexOfMaxValue]);
    }
  }, [sequence]);

  useEffect(() => {
    const holistic = new Holistic({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/holistic/${file}`;
      },
    });

    holistic.setOptions({
      modelComplexity: 1,
      smoothLandmarks: true,
      enableSegmentation: true,
      smoothSegmentation: true,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });

    holistic.onResults(onResults);

    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null
    ) {
      camera = new cam.Camera(webcamRef.current.video, {
        onFrame: async () => {
          await holistic.send({ image: webcamRef.current.video });
        },
        width: 640,
        height: 480,
      });
      camera.start();
    }
  }, []);

  return (
    <center>
      <div className="App">
        <br></br>
        <h3>Translated Sign</h3>
        <textarea
          readOnly
          id="translatedText"
          style={{ fontSize: 30 }}
          value={predictedWord}
          className="border border-dark p-3 w-100"
        ></textarea>
        <br />
        <br />
        <Webcam
          ref={webcamRef}
          style={{
            position: "right",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zindex: 9,
            width: "60%",
            marginBottom: "5%",
            display: "none",
          }}
        />{" "}
        <canvas
          ref={canvasRef}
          className="output_canvas"
          style={{
            position: "right",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zindex: 9,
            width: "60%",
            marginBottom: "5%",
          }}
        ></canvas>
        <div></div>
      </div>
    </center>
  );
}
