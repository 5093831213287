import React, { useState, useEffect } from "react";
import HolisticSign from "../../components/readsign/holisticsign";
import * as tf from "@tensorflow/tfjs";
import styles from "assets/jss/material-kit-react/views/components.js";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
const useStyles = makeStyles(styles);

export default function Home() {
  const [model, setModel] = useState();
  const classes = useStyles();

  //model
  const url = {
    model: "https://cpsmodel.s3.amazonaws.com/model.json",
  };

  //load model
  async function loadModel(url) {
    console.log("checking url:", url);
    try {
      const model = await tf.loadLayersModel(url.model);
      setModel(model);
      console.log("model loaded successfully");
    } catch (err) {
      console.log("model loading failed:", err);
    }
  }

  useEffect(() => {
    tf.ready().then(() => {
      loadModel(url);
    });
  }, []);

  return (
    <div>
      <Parallax image={require("assets/img/landing-bg.jpg").default}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>RSLI</h1>
                <h3 className={classes.subtitle}>
                  Try the keyword sign prediction
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <HolisticSign model={model} />
      </div>
    </div>
  );
}
